import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, updateDoc, doc } from "firebase/firestore";
import Swal from "sweetalert2";
import BtnSearch from '../images/buttons/search.png';
import BtnRefresh from '../images/buttons/refresh.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import AbsenteeIcon from '../images/buttons/absentee_icon_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';


function AbsenteeMng() {
    const navigate = useNavigate();
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);

    const [stdTime, setStdTime] = useState("");
    const [absentData, setAbsentdata] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [attendData, setAttendData] = useState([]);
    const [attendDataRaw, setAttendDataRaw] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호

    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const callDb = useCallback(async () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const date = now.getDate();

        const stdDate = new Date(year, month, date);
        const stdTimestamp = stdDate.getTime();

        // const hours = now.getHours().toString();
        // const minutes = now.getMinutes().toString();
        //설문조사 데이터 조회 변수
        const q = query(collection(db, "schedules"), where("daystart", "<=", stdTimestamp),
                    where("groupid", "==", "sd3"))
        
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        //let patientList = []; //진료시간 테이블 따로 빼면서 주석처리 23.05.08

        const queryData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        const hour = now.getHours().toString().padStart(2, '0');
        const min = now.getMinutes().toString().padStart(2, '0');

        const stdTime = hour+min;

        //오늘의 요일을 계산(일:0 월:1 화:2 ..)
        //계산된 요일값에 따라 일정테이블 데이터의 요일값에 대한 필터 적용하는 로직
        const day = now.getDay();
        const filteredData = queryData.filter(item => 
            item.dayend >= stdTimestamp &&
            item.starttime <= stdTime &&
            item.endtime >= stdTime
        )
        let dayFilteredData;
        if(day === 0){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.sunday === true 
                )
            })
        }else if(day === 1){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.monday === true 
                )
            })
        }else if(day === 2){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.tuesday === true 
                )
            })
        }else if(day === 3){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.wednesday === true 
                )
            })
        }else if(day === 4){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.thursday === true 
                )
            })
        }else if(day === 5){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.friday === true 
                )
            })
        }else{
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.saturday === true 
                )
            })
        }
        setAbsentdata(dayFilteredData);

        //학생 데이터
        const stuq = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
                                         where("auth", "==", 1), where("groupid", "==", "sd3"));
        const stusnapshot = await getDocs(stuq);

        const stuData = stusnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setStudentData(stuData);

        //출석 데이터
        const atdq = query(collection(db, "attendance"), where("entertime", "<=", now),
                            where("attenddate", "==", String(year)+String(month+1).padStart(2, '0')+String(date).padStart(2, '0')),
                            where("completeyn", "==", "N"), where("groupid", "==", "sd3")
                            )
        
        const atdsnapshot = await getDocs(atdq); 
        const atdqueryData = atdsnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        // stdData의 seatno 필드 데이터를 schData 추가
        const combinedData = atdqueryData
            .filter(student => {
                return stuData.some(absentee => absentee.stdphone === student.userphone);
            })
            .map((absentee) => {
                const matchingStudent = stuData.find((student) => student.userphone === absentee.stdphone);
                return {
                ...absentee,
                seatno: matchingStudent ? matchingStudent.seatno : "",
                school: matchingStudent ? matchingStudent.school : "",
                grade: matchingStudent ? matchingStudent.grade : "",
                gender: matchingStudent ? matchingStudent.gender : "",
                };
        });
        setAttendData(combinedData);
        setAttendDataRaw(combinedData);

        // 부재자관리에 전체학생이 나오게해달라는 요청에 따라
        // 렌더링쪽에서 attendData 대신 studentData를 사용하는것으로 바꿨고
        // 이름 필드를 stdname대신 username으로 변경함
        // const schedule = absentData.filter(item => item.userphone === doc.stdphone);
        // 에서 doc.stdphone 을 doc.userphone으로 변경
    }, [])

    useEffect(() => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth()+1;
        const date = now.getDate();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        setStdTime(year+"년 "+month+"월 "+date+"일 "+hours+"시 "+minutes+"분");
        callDb();
    },[callDb, updateState])

    const processUnauthAbsence = async (colId, unauthAbs) => {
        let unauthAbsYn = "";

        if(unauthAbs === "N"){
            unauthAbsYn = "Y";
        }else if(unauthAbs === "Y"){
            unauthAbsYn = "N";
        }

        try{
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth()+1;
            const date = now.getDate();

            const docRef = doc(db, "attendance", colId)
            await updateDoc(docRef, {
                unauthabsence: unauthAbsYn,
                unauthregtime: now,
                unauthdate: year.toString()+"-"+month.toString()+"-"+date.toString(),
            });

            if(unauthAbsYn === "Y"){
                Swal.fire({
                    icon: "success",
                    title: "처리 완료",
                    text: "무단부재자 등록이 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                    }
                });
            }else if(unauthAbsYn === "N"){
                Swal.fire({
                    icon: "success",
                    title: "처리 완료",
                    text: "무단부재자 해제가 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                    }
                });
            }
        }catch(error){
            console.log(error.message);
        }
    }

    const toUnauthAbsMng = () => {
        navigate("/unauthabsenteemng", {
            state: {
                studentdata: studentData,
            },
        })
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        // const nameValue = searhNameRef.current.value;
        const nameValue = props;
        setAttendData(attendDataRaw);
        
        if(nameValue !== ""){
            const filteredCustomer = attendDataRaw.filter(item => item.stdname.includes(nameValue));
            setAttendData(filteredCustomer);
        }else{
            callDb();
        }
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">부재자관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toUnauthAbsMng}
                        >무단부재자관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRef.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{width:"26.25rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6",
                                display:'flex',justifyContent:'center',alignItems:'center'
                    }}>
                        <div style={{fontWeight:500,fontSize:"1.5rem",color:"#0F5EFE"}}>{stdTime} 기준</div>
                        <img src={BtnRefresh} className="btn_icon" style={{marginLeft:"0.625rem",cursor:"pointer"}} alt='새로고침'
                            onClick={()=>setUpdateState(!updateState)}
                        ></img>
                    </div>
                </div>    
                <div style={{width:"100%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('stdname')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"5rem"}}>이름</div>
                    <div style={{width:"4.375rem"}}>좌석번호</div>
                    {/* <div style={{width:"150px"}}>전화번호</div> */}
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    {/* <div style={{width:"150px"}}>학부모 전화번호</div> */}
                    <div style={{width:"18.75rem"}}>일정명</div>
                    <div style={{width:"12.5rem"}}>요일</div>
                    <div style={{width:"9.375rem"}}>시간</div>
                    <div style={{width:"12.5rem"}}>기간</div>
                    <div style={{width:"6.25rem",textAlign:'center'}}>부재대상</div>
                    <div style={{width:"6.25rem"}}>무단부재처리</div>
                </div>
                {attendData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'stdname') {
                            // 이름을 문자열로 비교
                            return a.stdname.localeCompare(b.stdname);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        // const formatPhoneNumber = (phoneNumber) => {
                        //     const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                        //     const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                        //     if (match) {
                        //     return match[1] + '-' + match[2] + '-' + match[3];
                        //     }
                        
                        //     return phoneNumber;
                        // };

                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                        const schedule = absentData.filter(item => item.userphone === doc.stdphone);
                        
                        let absenteeYn = "";
                        let title = "";
                        let term = "";
                        let dayList = "";
                        let period = "";
                        if(schedule.length !== 0){
                            absenteeYn = "O";
                            title = schedule[0].title;
                            term = schedule[0].starthour+":"+schedule[0].startmin+" ~ "
                                    +schedule[0].endhour+":"+schedule[0].endmin;

                            if(schedule[0].monday){ dayList = dayList+"월 "; }
                            if(schedule[0].tuesday){ dayList = dayList+"화 "; }
                            if(schedule[0].wednesday){ dayList = dayList+"수 "; }
                            if(schedule[0].thursday){ dayList = dayList+"목 "; }
                            if(schedule[0].friday){ dayList = dayList+"금 "; }
                            if(schedule[0].saturday){ dayList = dayList+"토 "; }
                            if(schedule[0].sunday){ dayList = dayList+"일 "; }

                            const startScheDate = new Date(schedule[0].daystart);
                            const startYear = startScheDate.getFullYear().toString();
                            const startMonth = (startScheDate.getMonth()+1).toString();
                            const startDay = startScheDate.getDate().toString();
                            const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                            const endScheDate = new Date(schedule[0].dayend);
                            const endYear = endScheDate.getFullYear().toString();
                            const endMonth = (endScheDate.getMonth()+1).toString();
                            const endDay = endScheDate.getDate().toString();
                            const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                            period = formattedDateStart+" ~ "+formattedDateEnd;
                        }

                        let unauthProcessbtn;

                        if(doc.unauthabsence === "N"){
                            unauthProcessbtn = "등록";
                        }else{
                            unauthProcessbtn = "해제";
                        }

                        return(
                            <div style={{width:"100%",height:"2.5rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"2.5rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                {doc.unauthabsence !== 'Y' ? (
                                    <>
                                        <div style={{width:"5rem"}}>{doc.stdname}</div>
                                        <div style={{width:"4.375rem"}}>{doc.seatno}</div>
                                        {/* <div style={{width:"150px"}}>{formatPhoneNumber(doc.stdphone)}</div> */}
                                        <div style={{width:"12.5rem"}}>{userInfo}</div>
                                    </>
                                ) : (
                                    <>
                                        <div style={{width:"5rem",color:"#FF5B5B"}}>{doc.stdname}</div>
                                        <div style={{width:"4.375rem",color:"#FF5B5B"}}>{doc.seatno}</div>
                                        {/* <div style={{width:"150px",color:"#FF5B5B"}}>{formatPhoneNumber(doc.stdphone)}</div> */}
                                        <div style={{width:"12.5rem",color:"#FF5B5B"}}>{userInfo}</div>
                                    </>
                                )}
                                {/* <div style={{width:"150px"}}>{formatPhoneNumber(parentPhone)}</div> */}
                                <div style={{width:"18.75rem"}}>{title}</div>
                                <div style={{width:"12.5rem"}}>{dayList}</div>
                                <div style={{width:"9.375rem"}}>{term}</div>
                                <div style={{width:"12.5rem"}}>{period}</div>
                                <div style={{width:"6.25rem",textAlign:'center'}}>{absenteeYn}</div>
                                <div style={{width:"6.25rem"}}>
                                {doc.unauthabsence !== 'Y' ? (
                                    <button className="btn_moddel" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                ) : (
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                )}
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">부재자관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toUnauthAbsMng}
                        >무단부재자관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{width:"26.25rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6",
                                display:'flex',justifyContent:'center',alignItems:'center'
                    }}>
                        <div style={{fontWeight:500,fontSize:"1.5rem",color:"#0F5EFE"}}>{stdTime} 기준</div>
                        <img src={BtnRefresh} className="btn_icon" style={{marginLeft:"0.625rem",cursor:"pointer"}} alt='새로고침'
                            onClick={()=>setUpdateState(!updateState)}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('stdname')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"1rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"3rem"}}>
                    {/* <div style={{width:"120px"}}>이름</div>
                    <div style={{width:"200px"}}>전화번호</div>
                    <div style={{width:"200px"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"150px"}}>일정명</div>
                    <div style={{width:"150px"}}>시간</div>
                    <div style={{width:"100px",textAlign:'center'}}>부재대상</div>
                    <div style={{width:"100px"}}>무단부재처리</div> */}
                    {/* <div style={{width:"190px"}}>학부모 전화번호</div> */}
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"8%"}}>좌석번호</div>
                    <div style={{width:"12%"}}>전화번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"15%"}}>일정명</div>
                    <div style={{width:"15%"}}>시간</div>
                    <div style={{width:"10%",textAlign:'center'}}>부재대상</div>
                    <div style={{width:"10%"}}>무단부재처리</div>
                </div>
                {attendData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'stdname') {
                            // 이름을 문자열로 비교
                            return a.stdname.localeCompare(b.stdname);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const formatPhoneNumber = (phoneNumber) => {
                            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                            if (match) {
                            return match[1] + '-' + match[2] + '-' + match[3];
                            }
                        
                            return phoneNumber;
                        };

                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                        const schedule = absentData.filter(item => item.userphone === doc.stdphone);
                        
                        let absenteeYn = "";
                        let title = "";
                        let term = "";
                        if(schedule.length !== 0){
                            absenteeYn = "O";
                            title = schedule[0].title;
                            term = schedule[0].starthour+":"+schedule[0].startmin+" ~ "
                                    +schedule[0].endhour+":"+schedule[0].endmin;
                        }

                        let unauthProcessbtn;

                        if(doc.unauthabsence === "N"){
                            unauthProcessbtn = "등록";
                        }else{
                            unauthProcessbtn = "해제";
                        }

                        return(
                            <div style={{width:"95%",height:"3rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"3rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                {doc.unauthabsence !== 'Y' ? (
                                    <>
                                        {/* <div style={{width:"120px"}}>{doc.stdname}</div>
                                        <div style={{width:"200px"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                        <div style={{width:"200px"}}>{userInfo}</div> */}
                                        <div style={{width:"10%"}}>{doc.stdname}</div>
                                        <div style={{width:"8%"}}>{doc.seatno}</div>
                                        <div style={{width:"12%"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                        <div style={{width:"20%"}}>{userInfo}</div>
                                    </>
                                ) : (
                                    <>
                                        {/* <div style={{width:"120px",color:"#FF5B5B"}}>{doc.stdname}</div>
                                        <div style={{width:"200px",color:"#FF5B5B"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                        <div style={{width:"200px",color:"#FF5B5B"}}>{userInfo}</div> */}
                                        <div style={{width:"10%",color:"#FF5B5B"}}>{doc.stdname}</div>
                                        <div style={{width:"8%",color:"#FF5B5B"}}>{doc.seatno}</div>
                                        <div style={{width:"12%",color:"#FF5B5B"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                        <div style={{width:"20%",color:"#FF5B5B"}}>{userInfo}</div>
                                    </>
                                )}
                                {/* <div style={{width:"190px"}}>{formatPhoneNumber(parentPhone)}</div> */}
                                {/* <div style={{width:"150px"}}>{title}</div>
                                <div style={{width:"150px"}}>{term}</div>
                                <div style={{width:"100px",textAlign:'center'}}>{absenteeYn}</div>
                                <div style={{width:"100px"}}> */}
                                <div style={{width:"15%"}}>{title}</div>
                                <div style={{width:"15%"}}>{term}</div>
                                <div style={{width:"10%",textAlign:'center'}}>{absenteeYn}</div>
                                <div style={{width:"10%"}}>
                                {doc.unauthabsence !== 'Y' ? (
                                    <button className="btn_moddel" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                ) : (
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                )}
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={AbsenteeIcon} alt='부재자관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div>부재자관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div className="notselected" onClick={toUnauthAbsMng}>무단부재자관리</div>
                </div>
                <div className="timeinfo_area">
                    <div>{stdTime} 기준</div>
                    <img src={BtnRefresh} style={{width:'1.25rem',height:'1.25rem',cursor:"pointer",marginLeft:'0.38rem'}} alt='새로고침'
                        onClick={()=>setUpdateState(!updateState)}
                    ></img>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.12rem'}}>
                    <div className="btn_midside" 
                        onClick={() => {
                            if(sortCriteria === "seatno"){
                                handleSortClick('stdname')
                            }else{
                                handleSortClick('seatno')
                            }
                        }}
                    >
                        {sortCriteria === "seatno" ? "좌석순"
                        : "이름순"
                        }
                        <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="schedule">일정명</div>
                        <div className="schedule_time">시간</div>
                        <div className="absentee">부재대상</div>
                        <div className="unauthabsentee">무단부재처리</div>
                    </div>
                    {attendData
                        .sort((a, b) => {
                            if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'stdname') {
                                // 이름을 문자열로 비교
                                return a.stdname.localeCompare(b.stdname);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            const formatPhoneNumber = (phoneNumber) => {
                                const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                                const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                            
                                if (match) {
                                return match[1] + '-' + match[2] + '-' + match[3];
                                }
                            
                                return phoneNumber;
                            };

                            let gender;

                            if(doc.gender === "M"){
                                gender = "남";
                            }else if(doc.gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                            const schedule = absentData.filter(item => item.userphone === doc.stdphone);
                            
                            let absenteeYn = "";
                            let title = "";
                            let term = "";
                            if(schedule.length !== 0){
                                absenteeYn = "O";
                                title = schedule[0].title;
                                term = schedule[0].starthour+":"+schedule[0].startmin+" ~ "
                                        +schedule[0].endhour+":"+schedule[0].endmin;
                            }

                            let unauthProcessbtn;

                            if(doc.unauthabsence === "N"){
                                unauthProcessbtn = "등록";
                            }else{
                                unauthProcessbtn = "해제";
                            }

                            return(
                                <div className="body_area_mobile" key={doc.id}>
                                    {doc.unauthabsence !== 'Y' ? (
                                        <>
                                            <div className="name">{doc.stdname}</div>
                                            <div className="seat">
                                                <div className="seatno">{doc.seatno}</div>
                                            </div>
                                            <div className="phone">{formatPhoneNumber(doc.stdphone)}</div>
                                            <div className="personalinfo">{userInfo}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="name" style={{color:"#FF5B5B"}}>{doc.stdname}</div>
                                            <div className="seat">
                                                <div className="seatno">{doc.seatno}</div>
                                            </div>
                                            <div className="phone" style={{color:"#FF5B5B"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                            <div className="personalinfo" style={{color:"#FF5B5B"}}>{userInfo}</div>
                                        </>
                                    )}
                                    <div className="schedule">{title}</div>
                                    <div className="schedule_time">{term}</div>
                                    <div className="absentee">{absenteeYn}</div>
                                    <div className="unauthabsentee">
                                        {doc.unauthabsence !== 'Y' ? (
                                            <button className="unauthabsentee" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                        ) : (
                                            <button className="unauthabsentee" style={{color:"#8D8D8D"}}  onClick={()=>processUnauthAbsence(doc.id, doc.unauthabsence)}>{unauthProcessbtn}</button>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default AbsenteeMng;