import './App.css';
import './css/style.css'
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from './firebase_config.js';
import Swal from "sweetalert2";
import PrivateRoute from './PrivateRoute.js';
import AdminPage from './pages/AdminPage.js';
import Home from './pages/Home.js';
// import StudentReg from './pages/StudentReg.js';
import StudentMng from './pages/StudentMng.js';
import AttendMng from './pages/AttendMng.js';
import AttendChk from './pages/AttendChk.js';
import Login from './pages/Login.js';
// import Signup from './pages/Signup.js';
import PersonalInfo from './pages/PersonalInfo.js';
import AttendChkPhone from './pages/AttendChkPhone.js';
import ScheduleMng from './pages/ScheduleMng.js';
import AbsenteeMng from './pages/AbsenteeMng.js';
import NewModSche from './pages/NewModSchedule.js';
import NewModStu from './pages/NewModStudent.js';
import UnAuthAbs from './pages/UnauthAbsenteeMng.js';
import PopUp from './pages/PopUp.js';
import PointMng from './pages/PointMng.js';
import PointHistory from './pages/PointHistory.js';
import SpecialSchMng from './pages/SpecialSchMng.js';
import NewModSpecial from './pages/NewModSpecialSch.js';
import AbsenceMng from './pages/AbsenceMng.js';
import StudyHistMng from './pages/StudyHistMng.js';
import Notice from './pages/Notice.js';
import NoticeWrite from './pages/NoticeWrite.js';
import NoticeRead from './pages/NoticeRead.js';
import MobileMenu from './pages/MobileMenu.js';
import MessageMng from './pages/MessageMng.js';


// import Logo from './images/logo_1024.png';
import Menu from './images/menu.svg';
import Exit from './images/buttons/exit.svg';

const initialMenuState = {
  borderBottom: "#FFFFFF 4px solid",
  color: "#C1C1C1",
  colorTab: "#B6B6B6",
  weight: 400
};

const menuItems = [
  { id: 'attendChkPhone', label: '출석체크(전화번호)', path: '/attendchkphone' },
  { id: 'attendMng', label: '출결관리', path: '/attendmng' },
  { id: 'studentMng', label: '수강생관리', path: '/studentmng' },
  { id: 'specialSchMng', label: '결석일정관리', path: '/specialschmng' },
  { id: 'scheduleMng', label: '부재일정관리', path: '/schedulemng' },
  { id: 'absenteeMng', label: '부재자관리', path: '/absenteemng' },
  { id: 'pointMng', label: '상벌점관리', path: '/pointmng' },
  { id: 'studyHistMng', label: '학습일지관리', path: '/studyhistmng' },
  { id: 'notice', label: '공지사항관리', path: '/notice' },
  // { id: 'messagemng', label: '메시지관리', path: '/messagemng' },
];

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const loginbuttoncontent = useRef(''); //로그인 상태에 따라 로그인 로그아웃 버튼 활성화 변경값 담는 변수
  const [atdSysChk, setAtdSysChk] = useState('RFID시스템시작');
  const [rfidData, setRfidData] = useState('');
  let cardSeq = 0;

  const [menuState, setMenuState] = useState({});

  const [user, setUser] = useState(null);

  const isMenuNeed = location.pathname !== "/login";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  const selectMenu = (menuId) => {
    const newMenuState = { ...menuState };
    Object.keys(newMenuState).forEach(key => {
      newMenuState[key] = { ...initialMenuState };
    });
    newMenuState[menuId] = {
      borderBottom: "#11167B 4px solid",
      color: "#191919",
      colorTab: "#FFFFFF",
      weight: 500
    };
    setMenuState(newMenuState);
    const selectedItem = menuItems.find(item => item.id === menuId);
    if (selectedItem) {
      navigate(selectedItem.path);
    }
  };

  useEffect(() => {
    initializeMenuState();
  }, []);

  const initializeMenuState = () => {
    const initialState = Object.fromEntries(
      menuItems.map(item => [item.id, { ...initialMenuState }])
    );
    setMenuState(initialState);
  };

  const logoutRun = async () => {
    try {
      await signOut(auth);
      console.log("로그아웃");
      initializeMenuState();
      navigate("/");
    } catch (error) {
      console.error("로그아웃 중 오류 발생:", error);
    }
  };

  const toLoginPage = () => {
    navigate("/login") //페이지 이동
  }

  // const toSignUpPage = () => {
  //   navigate("/signup") //페이지 이동
  // }

  // function setScreenSize() {
  //   let vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // }
  // setScreenSize();
  // window.addEventListener('resize', setScreenSize); 

  //수강생 등록 페이지
  // const toStudentRegPage = () => {
  //   if(atdSysChk === "RFID시스템가동중"){
  //     setRfidData('');
  //     navigate("/studentreg") //페이지 이동
  //   }else{
  //     Swal.fire({
  //       icon: "warning",
  //       title: "RFID시스템미연결",
  //       text: "RFID시스템시작 후 기기연결하시기 바랍니다.",
  //       //showCancelButton: true,
  //       confirmButtonText: "확인",
  //       //cancelButtonText: "취소",
  //     }).then((res) => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (res.isConfirmed) {
  //             //확인 요청 처리
  //         }
  //     });
  //   }
  // }

  //출석체크(카드) 페이지
  const toAttendChkPage = () => {
    if(atdSysChk === "RFID시스템가동중"){
      setRfidData('');
      navigate("/attendchk") //페이지 이동
    }else{
      Swal.fire({
        icon: "warning",
        title: "RFID시스템미연결",
        text: "RFID시스템시작 후 기기연결하시기 바랍니다.",
        //showCancelButton: true,
        confirmButtonText: "확인",
        //cancelButtonText: "취소",
      }).then((res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
              //확인 요청 처리
          }
      });
    }
  }

  //rfid 리더기 연결
  const connectToSerialPort = async () => {
    if(atdSysChk === "RFID시스템가동중"){
      window.location.reload(false);  //페이지 refresh
    }else if(atdSysChk === "RFID시스템시작"){
      try {
        const port = await navigator.serial.requestPort();
        await port.open({ baudRate: 9600 });
  
        setAtdSysChk("RFID시스템가동중");
        const reader = port.readable.getReader();
        let collectedData = [];
  
        while (true) {
          const { value } = await reader.read();
  
          const rfidData = new TextDecoder().decode(value);
          collectedData.push(rfidData);
          const combinedTemp = collectedData.join('');
  
          if (combinedTemp.length === 14) {
            const combinedData = combinedTemp.substring(1,11) + cardSeq;
            setRfidData(combinedData);
            collectedData = [];
            cardSeq++;
            if (cardSeq >= 10){
              cardSeq = 0;
            }
          }
        }
      } catch (error) {
        console.error('Failed to connect to the serial port:', error);
      }
    }
  };

  return (
    <div className="wrap">
      <header className="desktop">
        <div className="main_title" onClick={()=>{
            initializeMenuState();
            if(user.email === "mawang86@naver.com"){
              navigate("/adminpage")
            }else{
              navigate("/")
            }
          }}
        >시대정신학원</div>
        <div className="menu_area">
          {/* <button style={{borderBottom:menu1,color:menu1Color}} onClick={connectToSerialPort}>{atdSysChk}</button> */}
          {/* <button style={{borderBottom:menu2,color:menu2Color}} onClick={toAttendChkPage}>출석체크(카드)</button> */}
          {menuItems.map(item => (
            <button
              key={item.id}
              style={{
                borderBottom: menuState[item.id]?.borderBottom || initialMenuState.borderBottom,
                color: menuState[item.id]?.color || initialMenuState.color
              }}
              onClick={() => selectMenu(item.id)}
            >
              {item.label}
            </button>
          ))}
        </div>
        <div className="login_area">{loginbuttoncontent.current}
          {user ? (
            // 로그인 상태일 때
            <button onClick={logoutRun}>로그아웃</button>
          ) : (
            // 로그아웃 상태일 때
            <button onClick={toLoginPage}>로그인</button>
            // <>
            //   <button onClick={toLoginPage}>로그인</button>
            //   <button onClick={toSignUpPage}>회원가입</button>
            // </>
          )}
        </div>
      </header>
      <header className="tablet">
        <div style={{width:"95%",height:"4rem",display:'flex',justifyContent:'space-between',
                    alignItems:'center'}}>
          <div className="main_title" onClick={()=>{
            initializeMenuState();
            navigate("/")
          }}
          >시대정신학원</div>
          <div className="login_area">{loginbuttoncontent.current}
            {user ? (
              // 로그인 상태일 때
              <button onClick={logoutRun}>로그아웃</button>
            ) : (
              // 로그아웃 상태일 때
              <button onClick={toLoginPage}>로그인</button>
              // <>
              //   <button onClick={toLoginPage}>로그인</button>
              //   <button onClick={toSignUpPage}>회원가입</button>
              // </>
            )}
          </div>
        </div>
        <div style={{width:"95%",height:"6rem",borderRadius:"0.5rem",backgroundColor:"#11167B",lineHeight:"6rem"}}>
          <div>
            {/* <button style={{color:menu1ColorTab,fontWeight:menu1Weight}} onClick={connectToSerialPort}>{atdSysChk}</button> */}
            {/* <button style={{color:menu2ColorTab,fontWeight:menu2Weight}} onClick={toAttendChkPage}>출석체크(카드)</button> */}
            {menuItems.map(item => (
              <button
                key={item.id}
                style={{
                  color: menuState[item.id]?.colorTab || initialMenuState.colorTab,
                  fontWeight: menuState[item.id]?.weight || initialMenuState.weight
                }}
                onClick={() => selectMenu(item.id)}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      </header>
      <header className="mobile">
        <div className="header_area">
          <div>시대정신학원</div>
          {location.pathname !== "/mobilemenu" ? 
            <img src={Menu} alt='메뉴' 
              onClick={()=>navigate('/mobilemenu')}
              style={{width:'1.5rem',height:'1.5rem',display:isMenuNeed ? 'block' : 'none',cursor:'pointer'
            }} />
          : <img src={Exit} alt='닫기' 
              onClick={()=>navigate(-1)}
              style={{width:'1.5rem',height:'1.5rem',cursor:'pointer'
            }} />
          }
          
        </div>
      </header>
      <Routes>
          <Route exact path="/" element={ <Home rfidData={rfidData}></Home> }></Route>
          <Route exact path="/personalinfo" element={ <PersonalInfo></PersonalInfo> }></Route>
          <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
            <Route exact path="/login" element={ <Login></Login> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/adminpage" element={ <AdminPage userAuth={user}></AdminPage> }></Route>
          </Route>
          {/* <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
            <Route exact path="/signup" element={ <Signup></Signup> }></Route>
          </Route> */}
          {/* <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/studentreg" element={ <StudentReg rfidData={rfidData} groupId={userGroup}></StudentReg> }></Route>
          </Route> */}
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/studentmng" element={ <StudentMng userAuth={user}></StudentMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/attendmng" element={ <AttendMng userAuth={user}></AttendMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/attendchk" element={ <AttendChk rfidData={rfidData} userAuth={user}></AttendChk> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/attendchkphone" element={ <AttendChkPhone userAuth={user}></AttendChkPhone> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/schedulemng" element={ <ScheduleMng userAuth={user}></ScheduleMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/absenteemng" element={ <AbsenteeMng userAuth={user}></AbsenteeMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/newmodschedule" element={ <NewModSche userAuth={user}></NewModSche> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/newmodstudent" element={ <NewModStu userAuth={user}></NewModStu> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/unauthabsenteemng" element={ <UnAuthAbs userAuth={user}></UnAuthAbs> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/popup" element={ <PopUp userAuth={user}></PopUp> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/pointmng" element={ <PointMng userAuth={user}></PointMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/pointhistory" element={ <PointHistory userAuth={user}></PointHistory> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/specialschmng" element={ <SpecialSchMng userAuth={user}></SpecialSchMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/newmodspecialsch" element={ <NewModSpecial userAuth={user}></NewModSpecial> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/absencemng" element={ <AbsenceMng userAuth={user}></AbsenceMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/studyhistmng" element={ <StudyHistMng userAuth={user}></StudyHistMng> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/notice" element={ <Notice userAuth={user}></Notice> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/noticewrite" element={ <NoticeWrite userAuth={user}></NoticeWrite> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/noticeread" element={ <NoticeRead userAuth={user}></NoticeRead> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/mobilemenu" element={ <MobileMenu userAuth={user}></MobileMenu> }></Route>
          </Route>
          <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
            <Route exact path="/messagemng" element={ <MessageMng userAuth={user}></MessageMng> }></Route>
          </Route>
      </Routes>
    </div>
  );
}
export default App;
