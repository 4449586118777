import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, where, getDocs, doc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import Swal from "sweetalert2";
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import DeleteFile from '../images/buttons/delete_mobile.svg';

function NoticeWrite(props) {
    const navigate = useNavigate();
    const userInfo = props.userAuth;
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const contentData = state.contentdata;

    let initialCategory = "";
    let initialTitle = "";
    let initialContent = "";
    let initialFiles = [];

    if (contentData !== "NEW") {
        initialCategory = contentData.code;
        initialTitle = contentData.title;
        initialContent = contentData.content;

        // 파일 정보 추출
        for (let i = 1; i <= 5; i++) {
            if (contentData[`attachedfilename${i}`]) {
                initialFiles.push({
                    name: contentData[`attachedfilename${i}`],
                    url: contentData[`attachedfile${i}`],
                    isExisting: true  // 기존 파일임을 표시
                });
            }
        }
    }

    const [title, setTitle] = useState(initialTitle !== "" ? initialTitle : "");
    const [content, setContent] = useState(initialContent !== "" ? initialContent : "");
    const [activeCategory, setActiveCategory] = useState(initialCategory !== "" ? initialCategory : "공지사항");
    const [selectedFiles, setSelectedFiles] = useState(initialFiles);

    const [isStudentChecked, setIsStudentChecked] = useState(false);
    const [isParentChecked, setIsParentChecked] = useState(false);

    const sendPushMessage = async (props) => {
        const { token, notification } = props;
        try {
          const response = await fetch('https://us-central1-sdacademy-a5ed3.cloudfunctions.net/api/send-notification', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, notification }),
          });
      
          if (response.ok) {
            console.log('Push notification sent successfully.');
          } else {
            console.error('Failed to send push notification:', await response.text());
          }
        } catch (error) {
          console.error('Error sending push notification:', error);
        }
      }

    // Firebase에서 membertype이 "C"인 사용자의 토큰을 가져오는 함수
    const getCTokens = async (props) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where("membertype", "==", props),
                    where("groupid", "==", "sd3"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data().token);
    }

    // 푸시 알람 로직
    const sendPushToAllC = async () => {
        const sendPushToGroup = async (memberType) => {
            const tokens = await getCTokens(memberType);
            const payload = {
                notification: {
                    title: '공지사항',
                    body: '새로운 공지사항이 등록되었습니다.',
                },
                data: {
                    type: 'notice',
                    // id: 'notice_id_123', // 실제 공지사항 ID
                }
            };
    
            for (const token of tokens) {
                await sendPushMessage({ ...payload, token });
            }
            console.log(`모든 ${memberType} 타입 사용자에게 푸시 메시지를 보냈습니다.`);
        };
    
        try {
            if (isStudentChecked && isParentChecked) {
                await Promise.all([
                    sendPushToGroup("C"),
                    sendPushToGroup("P")
                ]);
                console.log('모든 사용자에게 푸시 메시지를 보냈습니다.');
            } else if (isStudentChecked) {
                await sendPushToGroup("C");
            } else if (isParentChecked) {
                await sendPushToGroup("P");
            } else {
                console.log('선택된 그룹이 없습니다.');
            }
        } catch (error) {
            console.error('푸시 메시지 전송 중 오류 발생:', error);
        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    // const categories = ['공지사항', '기능소개'];
    const categories = ['공지사항'];

    const categoryStyle = (category) => ({
        background: activeCategory === category ? '#E5EFFF' : '#F8F8F8',
        color: '#535353',
        borderRadius: '0.25rem',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        marginRight: '0.5rem',
        fontWeight: 500,
    });

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const validNewFiles = newFiles.filter(file => file.size <= 10 * 1024 * 1024); // 10MB 이하
    
        // 기존 파일과 새 파일을 합침
        const allFiles = [...selectedFiles, ...validNewFiles];
    
        if (allFiles.length > 5) {
            showAlert("error", "파일 갯수 한도초과", "최대 5개의 파일만 선택할 수 있습니다.");
            return;
        }
    
        if (validNewFiles.length < newFiles.length) {
            showAlert("warning", "파일 용량 한도초과", "10MB를 초과하는 파일은 제외되었습니다.");
        }
    
        // 최대 5개까지만 유지
        const finalFiles = allFiles.slice(0, 5);
    
        setSelectedFiles(finalFiles);
    };

    const handleFileDelete = (indexToDelete) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToDelete));
    };

    const handleUpload = async () => {
        if( title !== "" && content !== ""){
            if(contentData === "NEW"){
                const now = new Date();
                const storage = getStorage();

                // 접속자 정보
                const userQuery = query(collection(db, "users"), where("userid", "==", userInfo.email));
                const userSnapshot = await getDocs(userQuery);
                const userData = userSnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                });  
                const userId = userData[0].userid;
                const userName = userData[0].username;

                // "code" 필드가 noticeCode인 데이터의 개수 가져오기
                const noticeQuery = query(collection(db, "bbsdata"), where("code", "==", activeCategory));
                const noticeSnapshot = await getDocs(noticeQuery);
                const noticeCount = noticeSnapshot.size+1;

                const dataId = activeCategory+noticeCount
                const docRef = doc(db, "bbsdata", dataId);

                // 파일 업로드 및 URL 가져오기
                const fileUrls = await Promise.all(selectedFiles.map(async (file, index) => {
                    const fileRef = storageRef(storage, `notice/${dataId+"_"+index}`);
                    await uploadBytes(fileRef, file);
                    return getDownloadURL(fileRef);
                }));
            
                // 데이터베이스에 저장할 객체 생성
                const bbsData = {
                    code: activeCategory,
                    seq: noticeCount,
                    userid: userId,
                    username: userName,
                    title: title,
                    content: content,
                    regdate: now,
                    reply: "N",
                    replycount: 0,
                    replyseq: 0,
                    replydest: "",
                    count: 0,
                    delyn: "N",
                    istop: "",
                    attachedfile1: "",
                    attachedfile2: "",
                    attachedfile3: "",
                    attachedfile4: "",
                    attachedfile5: "",
                    attachedfilename1: "",
                    attachedfilename2: "",
                    attachedfilename3: "",
                    attachedfilename4: "",
                    attachedfilename5: "",
                };
            
                // 첨부 파일 URL 추가
                fileUrls.forEach((url, index) => {
                    bbsData[`attachedfile${index + 1}`] = url;
                });

                // 첨부 파일 이름 추가
                selectedFiles.forEach((file, index) => {
                    bbsData[`attachedfilename${index + 1}`] = file.name;
                });

                // 데이터베이스에 저장
                try { 
                    await setDoc(docRef, bbsData);
                    // 폼 초기화
                    // setTitle('');
                    // setContent('');
                    // setSelectedFiles([]);
                    // setActiveCategory("공지사항");
                    navigate("/notice");
                } catch (error) {
                    console.error("Error adding document: ", error);
                    showAlert("error", "업로드 실패", "다시 시도해주세요.");
                }
            }else{
                const storage = getStorage();
                const docRef = doc(db, "bbsdata", contentData.id);
                const updatedData = {
                    title: title,
                    content: content,
                };

                // 새로 추가된 파일 업로드
                const newFiles = selectedFiles.filter(file => !file.isExisting);
                const uploadedFileUrls = await Promise.all(newFiles.map(async (file, index) => {
                    const fileRef = storageRef(storage, `notice/${contentData.id}_${index}_${new Date().getTime()}`);
                    await uploadBytes(fileRef, file);
                    return getDownloadURL(fileRef);
                }));

                // 기존 파일과 새 파일 정보 합치기
                const allFiles = [
                    ...selectedFiles.filter(file => file.isExisting),
                    ...newFiles.map((file, index) => ({
                        name: file.name,
                        url: uploadedFileUrls[index],
                        isExisting: false
                    }))
                ];

                // 파일 정보 업데이트
                for (let i = 1; i <= 5; i++) {
                    if (allFiles[i - 1]) {
                        updatedData[`attachedfile${i}`] = allFiles[i - 1].url;
                        updatedData[`attachedfilename${i}`] = allFiles[i - 1].name;
                    } else {
                        updatedData[`attachedfile${i}`] = "";
                        updatedData[`attachedfilename${i}`] = "";
                    }
                }

                try {
                    await updateDoc(docRef, updatedData);
                    
                    // 사용하지 않는 기존 파일 삭제 (옵션)
                    const unusedFiles = contentData.attachedfile1 ? [1, 2, 3, 4, 5].map(i => contentData[`attachedfile${i}`]).filter(url => url && !allFiles.some(file => file.url === url)) : [];
                    for (const url of unusedFiles) {
                        const fileRef = storageRef(storage, url);
                        await deleteObject(fileRef);
                    }

                    navigate("/notice");
                } catch (error) {
                    console.error("Error updating document: ", error);
                    showAlert("error", "업데이트 실패", "다시 시도해주세요.");
                }
            }
            sendPushToAllC();
        }else {
            showAlert("error", "필수입력 누락", "제목과 내용은 필수입력 값 입니다.");
        }
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <>
            <div className="content_wrap" style={{alignItems:'flex-start'}}>
                <div style={{width:"100%",marginTop:"1.25rem"}}>
                    <div style={{color:"#191919",fontWeight:"500",fontSize:"1.38rem"}}>
                        {contentData === "NEW" ? "공지사항 새로운 글 등록" : "공지사항 수정"}
                    </div>
                </div>
                <div style={{marginTop:"4.38rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>카테고리</div>
                    <div style={{display:'flex',marginLeft:"1rem"}}>
                    {categories.map((category) => (
                        <div
                            key={category}
                            style={categoryStyle(category)}
                            onClick={() => setActiveCategory(category)}
                        >
                            {category}
                        </div>
                    ))}
                    </div>
                </div>
                <div style={{marginTop:"1rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>제목</div>
                    <input style={{border:"1px grey solid",padding:'0.5rem 1rem',marginLeft:"1rem",width:"70rem"}}
                        placeholder="제목을 입력하세요" 
                        value={title}
                        onChange={handleTitleChange}
                    />
                </div>
                <div style={{marginTop:"1rem",display:'flex',alignItems:'flex-start'}}>
                    <div style={{width:"3rem"}}>내용</div>
                    <textarea style={{border:"1px grey solid",padding:'0.5rem 1rem',marginLeft:"1rem",width:"70rem",
                        height:"15rem",resize:'none'}}
                        placeholder="내용을 입력하세요"
                        value={content}
                        onChange={handleContentChange}
                    />
                </div>
                <div style={{marginTop:"1rem",display:'flex',alignItems:'flex-start'}}>
                    <div style={{width:"3rem",marginRight:"1rem"}}>푸시알람</div>
                    <div>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={isStudentChecked}
                                onChange={(e) => setIsStudentChecked(e.target.checked)}
                            /> 학생
                        </label>
                        <label style={{marginLeft: "1rem"}}>
                            <input 
                                type="checkbox" 
                                checked={isParentChecked}
                                onChange={(e) => setIsParentChecked(e.target.checked)}
                            /> 학부모
                        </label>
                    </div>
                </div>
                <div style={{marginTop:"1rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>첨부</div>
                    {/* <div style={{marginLeft: "1rem", flexGrow: 1}}>
                        {selectedFiles.length > 0 ? selectedFiles.map(file => file.name).join(', ') : '선택된 파일 없음'}
                    </div> */}
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput" style={{
                            margin: "0rem 1rem",
                            padding: '0.5rem 1rem',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            cursor: 'pointer'
                    }}>
                    파일선택
                    </label>
                    <div>※ 첨부파일은 최대 5개, 10MB를 넘을 수 없습니다.</div>
                </div>
                {selectedFiles.length > 0 && (
                    <div style={{
                        marginTop: "1rem",
                        marginLeft: "4rem",
                        padding: "1rem",
                        backgroundColor: "lightgrey",
                        borderRadius: "4px",
                        width:"70rem"
                    }}>
                        <h4>선택된 파일:</h4>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {selectedFiles.map((file, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <span>{file.name}</span>
                                {file.isExisting ? (
                                    <span style={{ marginLeft: '0.5rem', color: 'blue' }}>(기존 파일)</span>
                                ) : null}
                                <button 
                                    onClick={() => handleFileDelete(index)}
                                    style={{
                                        marginLeft:"1rem",
                                        backgroundColor: '#ff4d4d',
                                        color: 'white',
                                        border: 'none',
                                        padding: '0.25rem 0.5rem',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                삭제
                                </button>
                            </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{width:"100%",display:'flex',justifyContent:'center',marginTop:"3rem"}}>
                    <button onClick={handleUpload}
                        style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                    >업로드</button>
                    <button onClick={()=>navigate(-1)}
                        style={{padding:"0.5rem 1rem",background:"#FFF",color:"#000",cursor:'pointer'}}
                    >뒤로</button>
                </div>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",marginTop:"1.25rem"}}>
                    <div style={{color:"#191919",fontWeight:"500",fontSize:"1.38rem"}}>
                        {contentData === "NEW" ? "공지사항 새로운 글 등록" : "공지사항 수정"}
                    </div>
                </div>
                <div style={{width:'95%',marginTop:"4.38rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>카테고리</div>
                    <div style={{display:'flex',marginLeft:"1rem"}}>
                    {categories.map((category) => (
                        <div
                            key={category}
                            style={categoryStyle(category)}
                            onClick={() => setActiveCategory(category)}
                        >
                            {category}
                        </div>
                    ))}
                    </div>
                </div>
                <div style={{width:'95%',marginTop:"1rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>제목</div>
                    <input style={{border:"1px grey solid",padding:'0.5rem 1rem',marginLeft:"1rem",width:"70rem"}}
                        placeholder="제목을 입력하세요" 
                        value={title}
                        onChange={handleTitleChange}
                    />
                </div>
                <div style={{width:'95%',marginTop:"1rem",display:'flex',alignItems:'flex-start'}}>
                    <div style={{width:"3rem"}}>내용</div>
                    <textarea style={{border:"1px grey solid",padding:'0.5rem 1rem',marginLeft:"1rem",width:"70rem",
                        height:"15rem",resize:'none'}}
                        placeholder="내용을 입력하세요"
                        value={content}
                        onChange={handleContentChange}
                    />
                </div>
                <div style={{width:'95%',marginTop:"1rem",display:'flex',alignItems:'flex-start'}}>
                    <div style={{width:"3rem",marginRight:"1rem"}}>푸시알람</div>
                    <div>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={isStudentChecked}
                                onChange={(e) => setIsStudentChecked(e.target.checked)}
                            /> 학생
                        </label>
                        <label style={{marginLeft: "1rem"}}>
                            <input 
                                type="checkbox" 
                                checked={isParentChecked}
                                onChange={(e) => setIsParentChecked(e.target.checked)}
                            /> 학부모
                        </label>
                    </div>
                </div>
                <div style={{width:'95%',marginTop:"1rem",display:'flex',alignItems:'center'}}>
                    <div style={{width:"3rem"}}>첨부</div>
                    {/* <div style={{marginLeft: "1rem", flexGrow: 1}}>
                        {selectedFiles.length > 0 ? selectedFiles.map(file => file.name).join(', ') : '선택된 파일 없음'}
                    </div> */}
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput" style={{
                            margin: "0rem 1rem",
                            padding: '0.5rem 1rem',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            cursor: 'pointer'
                    }}>
                    파일선택
                    </label>
                    <div>※ 첨부파일은 최대 5개, 10MB를 넘을 수 없습니다.</div>
                </div>
                {selectedFiles.length > 0 && (
                    <div style={{
                        marginTop: "1rem",
                        padding: "1rem",
                        backgroundColor: "lightgrey",
                        borderRadius: "4px",
                        width:"95%"
                    }}>
                        <h4>선택된 파일:</h4>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {selectedFiles.map((file, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <span>{file.name}</span>
                                {file.isExisting ? (
                                    <span style={{ marginLeft: '0.5rem', color: 'blue' }}>(기존 파일)</span>
                                ) : null}
                                <button 
                                    onClick={() => handleFileDelete(index)}
                                    style={{
                                        marginLeft:"1rem",
                                        backgroundColor: '#ff4d4d',
                                        color: 'white',
                                        border: 'none',
                                        padding: '0.25rem 0.5rem',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                삭제
                                </button>
                            </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{width:"95%",display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                    <button onClick={handleUpload}
                        style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                    >업로드</button>
                    <button onClick={()=>navigate(-1)}
                        style={{padding:"0.5rem 1rem",background:"#FFF",color:"#000",cursor:'pointer'}}
                    >뒤로</button>
                </div>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title">
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={StudyIcon} alt='공지사항관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                        <div>{contentData === "NEW" ? "공지사항 새로운 글 등록" : "공지사항 수정"}</div>
                    </div>
                </div>
                <div style={{marginTop:"3rem"}}>
                    <div style={{display:'flex'}}>
                    {categories.map((category) => (
                        <div
                            key={category}
                            style={categoryStyle(category)}
                            onClick={() => setActiveCategory(category)}
                        >
                            {category}
                        </div>
                    ))}
                    </div>
                </div>
                <div className="notice_content">
                    <input
                        placeholder="제목을 입력하세요" 
                        value={title}
                        onChange={handleTitleChange}
                    />
                    <div style={{height:'1rem'}} />
                    <textarea 
                        placeholder="내용을 입력하세요"
                        value={content}
                        onChange={handleContentChange}
                    />
                    <div style={{height:'1rem'}} />
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <div className="notice_subtitle">푸시알람</div>
                    <div className="notice_checkbox">
                        <label>
                            <input 
                                type="checkbox" 
                                checked={isStudentChecked}
                                onChange={(e) => setIsStudentChecked(e.target.checked)}
                            /> 학생
                        </label>
                        <label style={{marginLeft: "1rem"}}>
                            <input 
                                type="checkbox" 
                                checked={isParentChecked}
                                onChange={(e) => setIsParentChecked(e.target.checked)}
                            /> 학부모
                        </label>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',margin:'1rem 0rem'}}>
                    <div className="notice_subtitle">첨부파일</div>
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput" className="notice_select_file">파일선택</label>
                </div>
                <div className="notice_subtitle alert">※ 첨부파일은 최대 5개, 10MB를 넘을 수 없습니다.</div>
                {selectedFiles.length > 0 && (
                    <div className="notice_filelist">
                        <ul style={{ listStyleType:'none',padding:0,width:'100%' }}>
                            {selectedFiles.map((file, index) => (
                            <li key={index} style={{ display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'0.5rem'}}>
                                <div style={{display:'flex'}}>
                                    <span>{file.name}</span>
                                    {file.isExisting ? (
                                        <span style={{ margin: '0rem 0.5rem', color: 'blue' }}>(기존 파일)</span>
                                    ) : null}
                                </div>
                                <img src={DeleteFile} alt='삭제' onClick={() => handleFileDelete(index)} />
                            </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                    <button className="notice_save" onClick={handleUpload}>업로드</button>
                    <div style={{width:"0.5rem"}} />
                    <button className="notice_save back" onClick={()=>navigate(-1)}>뒤로</button>
                </div>
            </div>
        </>
    );
}

export default NoticeWrite;